import { render, staticRenderFns } from "./ProductInformation.vue?vue&type=template&id=27cada20&scoped=true&"
import script from "./ProductInformation.vue?vue&type=script&lang=js&"
export * from "./ProductInformation.vue?vue&type=script&lang=js&"
import style0 from "./ProductInformation.vue?vue&type=style&index=0&id=27cada20&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27cada20",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
