<template>
  <v-app class="px-10 detail-container">
    <v-popup-loading :isLoading="isLoading" />
    <!-- Breadcrumbs -->
    <v-row style="flex: none">
      <v-col class="pb-0">
        <v-breadcrumbs
          :items="linkroute"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <h3 class="pageHeadline" t-data="page-headline">
          Order Detail
        </h3>
      </v-col>
    </v-row>
    <v-row v-if="orderDetail" align="start" justify="start" class="mt-0">
      <v-col cols="7" class="mt-0 pt-0">
        <v-card class="mt-5">
          <v-card-title class="mt-0 pt-0 px-0 pb-3">
            <h6 t-data="card-title" class="mb-0 card-title">
              Order Information
            </h6>
          </v-card-title>
          <v-row class="mt-0"><v-divider></v-divider></v-row>
          <v-card-text class="mt-0">
            <v-row class="mb-3">
              <v-col
                t-data="order-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Order No.
              </v-col>
              <v-col
                t-data="order-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Branch
              </v-col>
            </v-row>
            <v-row class="mb-5">
              <v-col
                t-data="order-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-bold"
                cols="6"
              >
                {{ orderDetail.order_number }}
              </v-col>
              <v-col
                t-data="order-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-bold"
                cols="6"
              >
                {{ getOrgName(orderDetail.org_codename) }}
              </v-col>
            </v-row>
            <v-row class="mb-3">
              <v-col
                t-data="order-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Channel
              </v-col>
            </v-row>
            <v-row>
              <v-col
                t-data="order-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-bold"
                cols="6"
              >
                {{
                  orderDetail.payment_channel in channelTextItems
                    ? channelTextItems[orderDetail.payment_channel]
                    : ''
                }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="pa-0"></v-divider>

          <v-card-text class="mt-0">
            <h6
              t-data="card-title"
              class="mb-0 card-title pb-4 headerTextColor--text"
            >
              Buyer Information
            </h6>
            <v-row class="mb-3">
              <v-col
                t-data="buyer-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Buyer Name
              </v-col>
              <v-col
                class="no-top-bottom-space information-label body-regular"
                cols="6"
                t-data="buyer-info-label"
              >
                Hospital Number (HN)
              </v-col>
            </v-row>
            <v-row class="mb-5">
              <v-col
                t-data="buyer-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-bold"
                cols="6"
              >
                {{ orderDetail.user_first_name }}
                {{ orderDetail.user_last_name }}
              </v-col>
              <v-col
                t-data="buyer-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-bold"
                cols="6"
              >
                {{ orderDetail.user_hn ? orderDetail.user_hn : '-' }}
              </v-col>
            </v-row>
            <v-row class="mb-3">
              <v-col
                t-data="buyer-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Mobile No.
              </v-col>
              <v-col
                t-data="buyer-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Email
              </v-col>
            </v-row>
            <v-row class="mb-5">
              <v-col
                t-data="buyer-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-bold"
                cols="6"
              >
                {{
                  orderDetail.user_phone_number
                    ? orderDetail.user_phone_number
                    : '-'
                }}
              </v-col>
              <v-col
                t-data="buyer-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-bold"
                cols="6"
              >
                {{ orderDetail.user_email ? orderDetail.user_email : '-' }}
              </v-col>
            </v-row>
            <v-row class="mb-3">
              <v-col
                t-data="buyer-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Shipping Address
              </v-col>
              <v-col
                t-data="buyer-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Billing Address
              </v-col>
            </v-row>
            <v-row class="mb-5">
              <v-col
                t-data="buyer-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-bold"
                cols="6"
              >
                {{
                  genAddress(
                    orderDetail.shipping_address_line1,
                    orderDetail.shipping_address_line2,
                    orderDetail.shipping_address_district,
                    orderDetail.shipping_address_province,
                    orderDetail.shipping_address_country,
                    orderDetail.shipping_address_zipcode
                  )
                }}
              </v-col>
              <v-col
                t-data="buyer-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-bold"
                cols="6"
              >
                {{
                  genAddress(
                    orderDetail.billing_address_line1,
                    orderDetail.billing_address_line2,
                    orderDetail.billing_address_district,
                    orderDetail.billing_address_province,
                    orderDetail.billing_address_country,
                    orderDetail.billing_address_zipcode
                  )
                }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-5">
          <v-card-title class="mt-0 pt-0 px-0 pb-3">
            <h6 t-data="card-title" class="mb-0 card-title">
              Product Information
            </h6>
          </v-card-title>
          <v-row class="mt-0"><v-divider></v-divider></v-row>
          <v-card-text class="mt-0">
            <template v-for="(item, index) in orderDetail.line_items">
              <product-information
                :key="index"
                :name="itemTitle(item)"
                :quantity="item.product_quantity"
                :price="item.product_total_price"
                :discount-price="item.product_normal_price"
                :sub-title="itemSubTitle(item)"
                :class="{'mb-2': index + 1 < orderDetail.line_items.length}"
              ></product-information>
              <v-divider
                :key="index"
                v-if="index + 1 < item.length"
              ></v-divider>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5" class="mt-0 pt-0">
        <v-card class="mt-5">
          <v-card-title class="mt-0 pt-0 px-0 pb-3">
            <h6 t-data="card-title" class="mb-0 card-title">
              Payment Information
            </h6>
          </v-card-title>
          <v-row class="mt-0"><v-divider></v-divider></v-row>
          <v-card-text class="mt-0">
            <v-row class="mb-3">
              <v-col
                t-data="payment-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Order Date/Time
              </v-col>
              <v-col
                t-data="payment-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Total Price
              </v-col>
            </v-row>
            <v-row class="mt-5 mb-5">
              <v-col
                t-data="payment-info-value"
                class="no-top-bottom-space d-inline-flex information-text body-regular"
                cols="6"
              >
                {{
                  orderDetail.create_date
                    ? dateFmt(orderDetail.create_date)
                    : '-'
                }}
              </v-col>
              <v-col
                t-data="payment-info-value"
                class="no-top-bottom-space d-inline-flex information-text"
                cols="6"
              >
                <h2>฿ {{ convertNumToComma(orderDetail.total_price) }}</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                t-data="payment-info-label"
                class="no-top-bottom-space information-label body-regular"
                cols="6"
              >
                Order Status
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="no-top-bottom-space d-inline-flex information-text"
                cols="6"
              >
                <order-status-chip
                  :status="orderDetail.status"
                ></order-status-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="pa-0"></v-divider>

          <v-card-text class="mt-0">
            <h6
              t-data="card-title"
              class="mb-0 card-title pb-4 headerTextColor--text"
            >
              Payment Status
            </h6>
            <payment-transaction
              class="pb-1"
              :orderDate="orderDetail.update_date"
              :paymentStatus="orderDetail.payment_status"
              :orderStatus="orderDetail.status"
              :orderNumber="orderDetail.order_number"
              :transactionId="orderDetail.payment_id"
              :paymentMethod="orderDetail.payment_method"
            ></payment-transaction>
            <v-divider
              class="pt-1"
              v-if="previousTransactions.length > 0"
            ></v-divider>
            <v-list class="mt-0 py-2" v-if="previousTransactions.length > 0">
              <v-list-group class="payment-status-container" :value="true">
                <template v-slot:activator>
                  <v-list-item-title class="mt-0"
                    >Hide {{ previousTransactions.length }} previous
                    transactions.</v-list-item-title
                  >
                </template>
                <template
                  v-for="(paymentTransaction, index) in previousTransactions"
                >
                  <v-divider
                    :key="`previos-divider-${index}`"
                    class="mt-4 pb-4"
                    v-if="index > 0"
                  ></v-divider>
                  <payment-transaction
                    :class="{'mt-5': index == 0}"
                    :key="`previos-transaction-${index}`"
                    :orderDate="paymentTransaction.update_date"
                    :paymentStatus="paymentTransaction.payment_status"
                    :orderStatus="paymentTransaction.order_status"
                    :orderNumber="orderDetail.order_number"
                    :transactionId="paymentTransaction.payment_id"
                    :paymentMethod="paymentTransaction.payment_method"
                  ></payment-transaction>
                </template>
              </v-list-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import OrderStatusChip from '@/components/order/OrderStatusChip'
import PaymentTransaction from '@/components/order/PaymentTransaction'
import ProductInformation from '@/components/order/ProductInformation'
import {channelText} from '@/constants/order'
import {
  capitalizeFirstLetter,
  convertDate,
  convertIntegerToCommaNumber,
  generateAddress
} from '@/helper/helper'
import {mapActions, mapState} from 'vuex'
import PopupLoading from '@/components/PopupLoading'
import {productTypeEnum} from '@/constants/order'

export default {
  components: {
    'v-popup-loading': PopupLoading,
    OrderStatusChip,
    PaymentTransaction,
    ProductInformation
  },
  data() {
    return {
      channelTextItems: channelText,
      linkroute: [
        {
          text: 'Order Management',
          disabled: false,
          href: '/order-management/order'
        },
        {
          text: 'Order Detail',
          disabled: true,
          href: '/'
        }
      ]
    }
  },
  mounted() {
    this.setListOrgInfo()
    this.fetchDetail(this.$route.params.orderId)
  },
  computed: {
    previousTransactions() {
      if (this.orderDetail.payment_events) {
        return this.orderDetail.payment_events.filter(
          e => e.id !== this.orderDetail.latest_payment_event_id
        )
      } else {
        return []
      }
    },
    ...mapState('order', ['orderDetail', 'orgList', 'isLoading'])
  },
  methods: {
    dateFmt: date => convertDate(date),
    getOrgName(orgCodename) {
      const org = this.orgList.all.find(o => o.codename === orgCodename)
      return org ? org.name : '-'
    },
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
    genAddress(line1, line2, district, province, country, zipcode) {
      return generateAddress(line1, line2, district, province, country, zipcode)
    },
    isPackageGroup(lineItem) {
      if (lineItem.product_type === productTypeEnum.PACKAGES) {
        return 'product_parent_title_th' in lineItem
      } else {
        return false
      }
    },
    itemTitle(lineItem) {
      if (this.isPackageGroup(lineItem)) {
        return lineItem.product_parent_title_th
      } else {
        return lineItem.product_title
      }
    },
    itemSubTitle(lineItem) {
      if (this.isPackageGroup(lineItem)) {
        return lineItem.product_title
      } else {
        return capitalizeFirstLetter(lineItem.product_type)
      }
    },
    ...mapActions('order', ['fetchDetail', 'setListOrgInfo'])
  }
}
</script>

<style lang="scss" scoped>
div {
  margin-top: 10px;
}
</style>

<style lang="scss">
.payment-status-container > .v-list-group__header {
  background-color: var(--grayscale-color) !important;
  color: var(--primary-text-color) !important;
}

.payment-status-container > div.v-list-group__items > div {
  margin-top: 0;
}
</style>
