<template>
  <div>
    <v-row class="mb-3">
      <v-col
        t-data="product-value"
        class="no-top-bottom-space information-text body-bold"
        cols="8"
      >
        {{ name }}
      </v-col>
      <v-col
        t-data="product-value"
        class="no-top-bottom-space information-label sub-body-regular"
        cols="1"
      >
        x{{ quantity }}
      </v-col>
      <v-col
        t-data="product-value"
        class="no-top-bottom-space information-text body-bold text-end"
        cols="3"
      >
        ฿ {{convertNumToComma(price)}}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        t-data="product-value"
        class="no-top-bottom-space information-text sub-body-regular"
        cols="8"
      >
        {{ subTitle }}
      </v-col>
      <v-col
        class="no-top-bottom-space information-label sub-body-regular"
        cols="1"
      >
      </v-col>
      <v-col
        t-data="product-value"
        v-if="discountPrice"
        class="no-top-bottom-space information-text sub-body-regular disableTextColor--text text-end discount-price"
        cols="3"
      >
        ฿ {{convertNumToComma(discountPrice)}}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {convertIntegerToCommaNumber} from '@/helper/helper'

export default {
  name: 'ProductInformation',
  props: {
    name: {type: String},
    quantity: {type: Number},
    price: {type: Number},
    subTitle: {type: String},
    discountPrice: {type: Number}
  },
  methods: {
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
  }
}
</script>
<style lang="sass" scoped>
.discount-price
  text-decoration: line-through
</style>