<template>
  <div>
    <v-row class="mb-3">
      <v-col
        t-data="payment-label"
        class="no-top-bottom-space information-label body-regular"
        cols="4"
      >
        Updated Date/Time:
      </v-col>
      <v-col
        t-data="payment-value"
        class="no-top-bottom-space information-label body-regular information-text"
        cols="8"
      >
        {{ orderDate ? dateFmt(orderDate) : '-' }}
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col
        t-data="payment-label"
        class="no-top-bottom-space information-label body-regular"
        cols="4"
      >
        Payment Status:
      </v-col>
      <v-col
        t-data="payment-value"
        class="no-top-bottom-space information-label body-regular"
        cols="8"
      >
        <payment-status v-if="paymentStatus" :status="paymentStatus"></payment-status>
        {{ paymentStatus ? '' : '-' }}
      </v-col>
    </v-row>
    <v-row class="mb-3" justify="center" align="center">
      <v-col
        t-data="payment-label"
        class="no-top-bottom-space information-label body-regular"
        cols="4"
      >
        Order Status:
      </v-col>
      <v-col
        t-data="payment-value"
        class="no-top-bottom-space information-label body-regular"
        cols="8"
      >
        <order-status-chip v-if="orderStatus" :status="orderStatus"></order-status-chip>
        {{ orderStatus ? '' : '-' }}
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col
        t-data="payment-label"
        class="no-top-bottom-space information-label body-regular"
        cols="4"
      >
        Order No:
      </v-col>
      <v-col
        t-data="payment-value"
        class="no-top-bottom-space information-label body-regular information-text"
        cols="8"
      >
        {{ orderNumber ? orderNumber : '-' }}
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col
        t-data="payment-label"
        class="no-top-bottom-space information-label body-regular"
        cols="4"
      >
        Invoice No:
      </v-col>
      <v-col
        t-data="payment-value"
        class="no-top-bottom-space information-label body-regular information-text"
        cols="8"
      >
        {{ transactionId ? transactionId : '-' }}
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col
        t-data="payment-label"
        class="no-top-bottom-space information-label body-regular"
        cols="4"
      >
        Payment Method:
      </v-col>
      <v-col
        t-data="payment-value"
        class="no-top-bottom-space information-label body-regular information-text"
        cols="8"
      >
        {{ paymentMethod ? paymentMethodItems[paymentMethod] : '-' }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrderStatusChip from '@/components/order/OrderStatusChip'
import PaymentStatus from '@/components/order/PaymentStatus'
import {paymentMethod} from '@/constants/order'
import {convertDate} from '@/helper/helper'

export default {
  name: 'PaymentTransaction',
  components: {
    OrderStatusChip,
    PaymentStatus
  },
  props: {
    orderDate: {type: String},
    paymentStatus: {type: String},
    orderStatus: {type: String},
    orderNumber: {type: String},
    transactionId: {type: String},
    paymentMethod: {type: String},
  },
  data() {
    return {
      paymentMethodItems: paymentMethod,
    }
  },
  methods: {
    dateFmt: date => convertDate(date),
  }
}
</script>
<style lang="sass" scoped>
.discount-price
  text-decoration: line-through
</style>